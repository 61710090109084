
import React, { useState } from "react";
import * as reactstrap from 'reactstrap';
import {useLocation} from "wouter"
import useUser from 'hooks/useUser'
import { useEffect } from "react";
import './Login.css'

export default function Login({onLogin}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, navigate] = useLocation()
  const {isLoginLoading, hasLoginError, login, isLogged} = useUser()

  useEffect(() => {
    if (isLogged) {
      navigate('/')
      onLogin && onLogin()
    }
  }, [isLogged, navigate, onLogin])


  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password })
  };

  return (
    <>
      {isLoginLoading && <strong>Comprobación de credenciales...</strong>}
      {!isLoginLoading &&
        <reactstrap.Form className='form' onSubmit={handleSubmit}>

          <reactstrap.Label>
            Nombre Usuario
            <reactstrap.Input
            placeholder="Nombre Usuario"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          </reactstrap.Label>

          <reactstrap.Label>
            Contraseña
            <reactstrap.Input
              type="password"
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </reactstrap.Label>
          {' '}
          <reactstrap.Button className='btn'>Iniciar Sesión</reactstrap.Button>
        </reactstrap.Form>
      }
      {
        hasLoginError && <strong>Las credenciales no son válidas</strong>
      }
    </>
  );
}
