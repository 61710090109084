import React, {useState} from "react";
import registerService from "services/register";
import { useForm, ErrorMessage } from 'react-hook-form'
import { Button, Form, Input } from 'reactstrap';

export default function Register() {
  const {handleSubmit, register, errors} = useForm()

  const [registered, setRegistered] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = values => {
    setIsSubmitting(true)
    registerService(values)
      .then(() => {
        setRegistered(true)
        setIsSubmitting(false)
      })
  }

  if (registered) {
    return <h4>
      ¡Felicidades ✅! ¡Has sido registrado correctamente!
    </h4>
  }

  return (
    <>
      <Form className='form' onSubmit={handleSubmit(onSubmit)}>
        <Input
          className={errors.username ? 'error' : ''}
          name="Nombre de usuario"
          placeholder="Nombre de usuario"
          ref={register({ required: 'Es requerido' })}
        />
        <ErrorMessage errors={errors} name='username' as="small"/>

        <Input
          className={errors.password ? 'error' : ''}
          name="Contraseña"
          placeholder="Contraseña"
          ref={register({ required: 'Es requerido' })}
          type='password'
        />
        <ErrorMessage errors={errors} name='password' as="small" />

        <Button className="btn" disabled={isSubmitting}>
          Salvar
        </Button>
      </Form>
    </>
  );
}