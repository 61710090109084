import React, { Suspense } from "react";
import { Link, Switch, Route } from "wouter";

import Header from "components/Header";

import Departament from 'pages/Register'
import Register from 'pages/Register'
import Login from "pages/Login";

import ErrorPage from "pages/ErrorPage";
import { UserContextProvider } from "context/UserContext";

import "./App.css"

const HomePage = React.lazy(() => import("./pages/Home"));

export default function App() {
  return (
    
    <UserContextProvider>      
          <div className="App container">
            <h3 className="d-flex justify-content-center m-3">
              WSN-FacturaEC
            </h3>                
              <Link to="/">
                  <figure className="App-logo">
                    <img alt="WSN logo" src="/logo.png" />
                  </figure>
                </Link>
                <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light bg-light" >             
          <Header />
          </nav>
        </div>
         <Suspense fallback={null}>         
          <section className="App-content">            
              <Switch>
                <Route component={HomePage} path="/" />                
                <Route component={Login} path="/login" />
                <Route component={Register} path="/register" />
                <Route component={ErrorPage} path="/:rest*" />
              </Switch>
          </section>
        </Suspense>
      </div>
    </UserContextProvider>
  );
}
