import React from 'react'
import {useRoute, Link, Route} from 'wouter'

import useUser from 'hooks/useUser'
import './Header.css'

export default function Header () {
  const {isLogged, logout} = useUser()
  const [match] = useRoute("/login");

  const handleClick = e => {
    e.preventDefault()
    logout()
  }

  const renderLoginButtons = ({isLogged}) => isLogged
    ?    
    <nav class="nav nav-pills flex-column flex-sm-row">       
    
      <ul className="nav nav-pills nav-fill">
        <li className="badge badge-primary">
        
          <Link className="btn btn-light btn-bd-primary" to="/Department">
            Departamento
          </Link>
        </li>
        <li className="badge badge-primary">
          <Link className="btn btn-light btn-bd-primary" to="/Employee">
            Empleado
          </Link>
        </li>
        <li className="badge badge-primary">
          <Link className="btn btn-light btn-bd-primary" to='/Home' onClick={handleClick}>
            Cerrar Sesión
          </Link>
        </li>
      </ul>
    </nav>
    : <>
      <nav className="navbar navbar-expand-sm bg-light navbar-dark">        
        <ul className="navbar-nav">
          <Link className="btn btn-light btn-outline-primary" to='/login'>
            Iniciar Sesión
          </Link>
          <Link className="btn btn-light btn-outline-primary" to='/register'>
            Registrarse
          </Link>          
        </ul>
        
      </nav>

    </>

  const content = match
    ? null
    : renderLoginButtons({isLogged})

  return (
    <header className='gf-header'>
      {content}
    </header>
  )
}